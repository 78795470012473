<template>
    <CCard class="CompanyList">
        <CCardBody>
            <div class="company-list-container">
                <CRow class="gx-0 gx-lg-3 justify-content-start w-100">
                    <CCol class="GCFilterMaxWidth col-12 col-md-12 col-lg-9 col-xl-10 col-xxl-10">
                        <GCFilter component="Company" :except-column="[]" :advanced-search="false"
                                  @search="(val) => onTimeoutSearch(() => fetchData(val))"/>
                    </CCol>

                    <CCol class="bulkAction col-12 col-md-12 col-lg-3 col-xl-2 col-xxl-2 text-lg-center mt-3 mt-lg-0">
                        <CDropdown :key="`${canSendEmail}-${selectedCompanies.length}`" color="primary"
                                   class="w-100 mb-3">
                            <CDropdownToggle color="primary" variant="outline" :disabled="!selectedCompanies.length">
                                Bulk Action
                            </CDropdownToggle>
                            <CDropdownMenu>
                                <CDropdownItem @click="exportCompanies">Export Companies</CDropdownItem>
                                <CDropdownItem @click="handleSendEmail">Send Email</CDropdownItem>
                                <CDropdownItem @click="eventRemoveCompany">Remove</CDropdownItem>
                            </CDropdownMenu>
                        </CDropdown>
                    </CCol>
                </CRow>
            </div>

            <SmartTable :items="companies" :items-per-page="parseInt(perPage)" :columns="fields" :sorter-value="sort"
                        selectable="id" :selected-items="selectedCompanies" :loading="loadingTable"
                        :active-page="activePage" :per-page="perPage" :pages="pages"
                        :clear-text="search.length ? 'No search results found' : 'No Data yet'"
                        @sorter-change="onSort" @selected-items-change="onSelect"
                        @update-active-page="(value) => (activePage = value)"
                        @update-per-page="(value) => (perPage = value)">
                <template #primary_contact_user.full_name="{ item, column }">
                        <span v-if="column.key === 'primary_contact_user.full_name'">
                          {{ item.primary_contact_user.full_name }}
                        </span>
                </template>
                <template #primary_contact_user.email="{ item, column }">
                        <span v-if="column.key === 'primary_contact_user.email'">
                            <a :href="'mailto:' + item.primary_contact_user.email">
                                {{ item.primary_contact_user.email }}
                            </a>
                        </span>
                </template>
                <template #primary_contact_user.phone="{ item, column }">
                        <span v-if="column.key === 'primary_contact_user.phone'">
                            <a :href="'tel:' + item.primary_contact_user.phone">
                                {{ item.primary_contact_user.phone }}
                            </a>
                        </span>
                </template>
            </SmartTable>
        </CCardBody>
    </CCard>
</template>

<script>
import CInputDateRange from '@/components/CInputDateRange'
import permissions from '@/utils/constants/permissions'
import GCFilter from '@/components/GCFilter/index'
import CPagination from '@/components/CPagination'
import Gate from '@/services/Gate/Gate'
import {notifyByPlan} from '@/services/Notify/Toasts'
import onTimeoutSearch from '@/mixin/searchTimeout'
import Companies from "@/api/v2/endpoints/Companies";

export default {
    name: 'CompaniesList',
    mixins: [onTimeoutSearch],
    inject: ['toast'],
    components: {CInputDateRange, GCFilter, CPagination},
    data() {
        return {
            companies: [],
            activePage: 1,
            pages: 1,
            perPage: 10,
            sort: {column: '', state: ''},
            search: '',
            searchDate: {},
            loadingTable: false,
            selectedCompanies: [],
            fields: [
                {
                    key: 'name',
                    label: 'Company Name',
                    link: {name: 'CompanyInfo', params: {id: 'id'}, iconNewTab: false},
                },
                {
                    key: 'type',
                    label: 'Company Type',
                    format: (item) => item?.capitalize(),
                },
                {
                    key: 'subcontractor_projects_amount',
                    label: 'Project',
                    link: {name: 'CompanyProjects', params: {id: 'id'}},
                    format: (v) => `${v}`,
                },
                {
                    key: 'subcontractor_tasks_amount',
                    label: 'Tasks',
                    link: {name: 'CompanyTasks', params: {id: 'id'}},
                    format: (v) => `${v}`,
                },
                {
                    key: 'subcontractor_overdue_tasks_amount',
                    label: 'Tasks Overdue',
                    link: {
                        name: 'CompanyTasks',
                        params: {id: 'id'},
                        query: {is_overdue: true},
                    },
                    format: (v) => `${v}`,
                },
                {
                    key: 'primary_contact_user.full_name',
                    label: 'Contact Name',
                },
                {
                    key: 'primary_contact_user.email',
                    label: 'Contact Email',
                    email: true
                },
                {
                    key: 'primary_contact_user.phone',
                    label: 'Contact Mobile',
                },
            ]
        }
    },
    computed: {
        canSendEmail() {
            return this.authUser.can(permissions.COMPANIES_COMMUNICATION)
        },
    },
    watch: {
        pages() {
            if (this.activePage > this.pages) {
                this.activePage = this.activePage - 1
            }
        },
        perPage() {
            this.activePage = 1
            this.fetchData && this.fetchData()
        },
        activePage() {
            if (this.activePage > this.pages) {
                this.activePage = this.pages
            } else if (this.activePage <= 0) {
                this.activePage = 1
            } else {
                this.fetchData && this.fetchData()
            }
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.applyPrefiltering()
            if (!this.$route.query.search) this.fetchData()
        })
    },
    methods: {
        onSelect(companies) {
            this.selectedCompanies = companies
            this.$emit('onSelect', this.selectedCompanies)
        },
        async handleSendEmail() {
            const check = await Gate.can('communication', 'employee')
            if (!check) {
                notifyByPlan()
                return false
            }
            this.$router.push({
                name: 'SendCommunication',
                query: {
                    users: this.selectedCompanies,
                    field: 'company_id'
                },
            })
        },
        onSort(sort) {
            this.sort = sort
            this.fetchData && this.fetchData()
        },
        applyPrefiltering() {
            if (
                this.$route.query &&
                this.$route.query.prefilter &&
                Object.keys(this.$route.query).some((param) =>
                    param.match(/^filter\[.+\]$/),
                )
            ) {
                const query = this.$route.query
                Object.keys(this.$route.query)
                    .filter((param) => param.match(/^filter\[.+\]$/))
                    .forEach((key) => {
                        let val = query[key]
                        let normalizedKey = key.replace('filter[', '').replace(']', '')
                        if (this[normalizedKey] !== undefined) {
                            this[normalizedKey] = val
                        }
                    })
            }
        },
        async eventRemoveCompany() {
            const check = await Gate.can('deleteList', 'company')
            if (!check) {
                notifyByPlan()
                return false
            }
            const x = this.selectedCompanies
                .map((id) => this.companies.find((i) => i.id === id))
                .filter((c) => c.projects_count)
                .map((i) => i.name)
            if (x.length) {
                this.toast(
                    'error',
                    'Please delete all scopes assigned to company:' +
                    ' ' +
                    x.join(', ') +
                    ' ' +
                    'before removing it',
                )
                return
            }
            this.$http.companies
                .removeCompany({company_id: this.selectedCompanies})
                .then(() => {
                    this.toast('success', 'The company has been removed from list successfully')
                    this.fetchData()
                })
        },
        fetchData(f = {}) {
            this.search = f.search || this.$route.query.search || this.search
            this.toggleLoadingTable();

            Companies
                .index(this.defineFetchDataParams(f))
                .then(response => {
                    this.companies = response.data;
                    this.pages = response.meta.last_page;
                })
                .finally(() => this.toggleLoadingTable())
        },
        defineFetchDataParams(f = {}) {
            return {
                ...f,
                paginate: 1,
                per_page: this.perPage,
                page: this.activePage,
                order_by: this.sort && this.sort.column,
                order_dir: this.sort && this.sort.state,
                company_data: 'id,name,type',
                status: ['active'],
                with_primary_contact_user: 'full_name,email,phone',
                with_subcontractor_projects_amount: 1,
                with_subcontractor_tasks_amount: 1,
                with_subcontractor_overdue_tasks_amount: 1,
            };
        },
        setCheck(value) {
            this.selectedCompanies = value.map((i) => i.id)
        },
        exportCompanies() {
            window.open(`${process.env.VUE_APP_BASE_API_URL}${this.$http.companies.exportCompany()}?companies[]=${this.selectedCompanies.join('&companies[]=',)}`);
        },
        toggleLoadingTable() {
            this.loadingTable = !this.loadingTable;
        }
    },
}
</script>

<style lang="scss" scoped>
@import 'style';

.company-list-container {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    flex-wrap: wrap;
}
</style>
